import { Container, CssBaseline, ThemeProvider } from '@mui/material';
import React from 'react';
import SEO from '../components/seo';
import { theme } from '../style/theme';

function Imprint() {
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <CssBaseline />
        <h1>Impressum</h1>
        <h2>Angaben gemäß § 5 TMG:</h2>
        <p>
          Polymodal GmbH
          <br />
        </p>
        <h3>Postanschrift:</h3>
        <p>
          An der Dammheide 8
          <br />
          60486, Frankfurt am Main
          <br />
        </p>
        <h3>Kontakt:</h3>
        <p>
          Telefon: +49(0)
          <span>179</span>
          919
          <span>7833</span>
          <br />
          E-Mail: hello
          <span>@polymodal.de</span>
        </p>
        <p />
        <h3>Vertreten durch:</h3>
        <p>
          Philip Iske - Geschäftsführer
          <b />
        </p>
        <p />
        <h3>Eingetragen am</h3>
        <p>
          Handelsregister B des Amtsgerichts Frankfurt am Main
          <br />
          HRB 127826
          <br />
        </p>
        <p />
        <p><br /></p>
        <p />
        <h2>Hinweise zur Website</h2>
        <p />
        <h2>Information gemäß § 36 VSBG</h2>
        <p>
          Gemäß § 36 VSBG (Verbraucherstreitbeilegungsgesetz –
          Gesetz über die alternative Streitbeilegung in Verbrauchersachen)
          erklärt der Betreiber dieser Website:
        </p>
        <p>
          Wir sind weder bereit noch verpflichtet,
          an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </p>
        <p />
        <p>
          <em>
            Das Impressum wurde mit dem
            {' '}
            <a href="https://www.activemind.de/datenschutz/impressums-generator/">Impressums-Generator der activeMind AG</a>
            {' '}
            erstellt.
          </em>
        </p>
      </Container>
    </ThemeProvider>
  );
}

export default Imprint;

export function Head() {
  return <SEO title="Imprint" />;
}
